.blog {
	h1 {
		margin-top: 0;
		a {
			color: @gray-dark;
			&:hover {color: @gray-darker; text-decoration: none;}
		}
	}

	blockquote {margin: 20px 0;}
	
	ul.post-info {
		margin: 0 0 20px;
		li {
			font-size: @font-size-small;
			a:hover {text-decoration: none;}
			color: @gray;
			padding-right: 20px;
			padding-left: 0;
			i {color: @gray-light; margin-right: 5px;}
		}
	}

	.well.share-story {
		h3 {
			margin: 3px 0;
		}
		.btn-social {
			width: 34px;
			height: 34px;
			border-radius: 50%;
			text-align: center;
			padding: 6px 0;
		}
	}

	.about-author {
		.avatar {
			width: 80px;
			height: 80px;
		}

		a {color: @gray-dark}
		a:hover {color: @gray-darker; text-decoration: none;}
	}

	.featured-post {
		border: 1px solid @border-darker;
		background: @panel-bg;
		margin-bottom: 20px;
		border-radius: @border-radius-base;

		.featured-body {
			h1 {margin-top: 0; margin-bottom: 20px; a{color: @gray-dark;} a:hover{color: @gray-darker; text-decoration: none;}}
			@media (max-width: @screen-xs) {
				h1 {font-size: @font-size-h3; margin-top: 0; margin-bottom: 20px; a{color: @gray-dark;} a:hover{color: @gray-darker; text-decoration: none;}}
			}
			p {margin: 0;}
			p + p {margin-top: 10px;}
			.featured-photo {
				margin: 0 -20px 20px;
			}
		}

		.featured-body, .featured-footer {
			padding: 20px;

		}
		.featured-footer {
			&:extend(.clearfix all);
			border-top: 1px solid @border-lighter;
			ul.post-info {
				margin: 4px 0;
				li {
					font-size: @font-size-small;
					a:hover {text-decoration: none;}
					color: @gray;
					padding-right: 20px;
					padding-left: 0;
					i {color: @gray-light; margin-right: 5px;}
				}
			}
			.post-info-container {float: left;}
			.post-more {float: right;}

			 @media (max-width: @screen-md) {

				.post-info-container {float: none;}
				.post-more {float: none;}

				ul.post-info {margin-bottom: 20px; margin-top: 0;}
			 }
		}
	}

	.blog-photos-thumb a {margin: 1px;}

	.blog-post-list {
		a {display: inline;}
	}
	.blog-post-list {
		.media {
			> .pull-left {margin-right: 15px; margin-top: 5px;}
			a.post-title {font-size: @font-size-base; &:hover{text-decoration: none;}}
			padding: 15px 20px 20px;
			border-bottom: 1px solid @border-lighter;
			margin: 0;
			&:last-child {margin-bottom: 0;}
			p {padding-top: 5px; margin-bottom: 0; color: @gray; font-size: @font-size-small;}
			p + p {padding-top: 5px;}
			.time {padding-top: 0px; color: @gray-light; display: block; font-size: @font-size-mini;}
			.media-object {width: 50px; border-radius: @border-radius-small;}
			&:last-child {border-bottom: none;}
		}
	}


	.column-post {
		margin-bottom: 20px;
		background: @panel-bg;
		border-radius: @border-radius-base;
		border: 1px solid @border-darker;
		
		.column-body {
			padding: 20px 0;
			h3 {margin-top: 0; margin-bottom: 20px; padding: 0 20px; a{color: @gray-dark}a:hover {color: @gray-darker; text-decoration: none;}}
			h3 + p {margin-top: 0}
			p {margin-bottom: 0; margin-top: 20px; padding: 0 20px;}
			p + p {margin-top: 10px;}
		}
		.column-footer {
			&:extend(.clearfix all);
			padding: 20px;
			border-top: 1px solid @border-lighter;
			ul.post-info {
				margin: 4px 0;
				li {
					font-size: @font-size-small;
					a:hover {text-decoration: none;}
					color: @gray;
					padding-right: 15px;
					padding-left: 0;
					i {color: @gray-light; margin-right: 5px;}
				}
			}
			.post-info-container {float: left;}
			.post-more {float: right;}

			@media (max-width: @screen-md) {

				.post-info-container {float: none;}
				.post-more {float: none;}

				ul.post-info {margin-bottom: 20px; margin-top: 0;}
			}
		}
	}

	.column-post-img + .column-post {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		border-top: none;
	}



	
	.comments {
		.media-heading a:hover {text-decoration: none;}
		.media:first-child {border: none; margin-top: 0; padding-top: 0}
		.media {
			margin-top: 20px;
			padding-top: 20px;
			border-top: 1px dashed @border-lighter;
		}
		.media-body {
			a.person {color: @gray-dark; &:hover{text-decoration: none; color: @gray-darker;}}
			a.reply {margin-left: 10px; &:hover{text-decoration: none;}}
			.time {color: @gray-light;}
			p {margin-bottom: 0}
			p + p {margin-top: 20px}
		}
		.media-object {
			border-radius: 50%;
			height: 40px;
			width: 40px;
		}
	}



	/* Portfolio */
	.portfolio {
		&:extend(.clearfix all);
		padding-left: 0;

		.item {
			position: relative;
			margin-bottom: 20px;
			border-radius: @border-radius-base;
			-webkit-mask-image: -webkit-radial-gradient(circle,white,black);

			img, .caption-wrapper {
				.transition(~"all 200ms");
			}

			img {
				display: block;
				width: 100%;
			}

			.caption-wrapper {
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;

				text-align: center;

				.opacity(0);

				//For vertical-align
				-webkit-transform-style: preserve-3d;
	  			-moz-transform-style: preserve-3d;
	  			transform-style: preserve-3d;

				.caption {
					&:extend(.vertical-align);

					left: 10%;
					right: 10%;
				}

				h1, h2, h3, h4, h5, h6, p {
					color: #fff;
				}

				.buttons {
					.mt20();
					.btn-default-alt {
						color: @border-darker;
						padding: 6px 0;
						border-radius: 50%;
						width: 34px;
						text-align: center;
					}
				}
			}

			&:hover {
				img {
					.scale(1.1);
				}
				.caption-wrapper {
					.rgba(black,0.8);
					.opacity(1);
				}

			}

		}
	}

}


.tag-cloud .label {margin-bottom: 2px;}