//scaffolding.less

html {-ms-overflow-style: scrollbar;} //Always show scrollbar in IE10+ instead of disappearing

:focus, :active {outline:0 !important;}
::-moz-focus-inner {border:0 !important;}

body {font-weight: @font-weight-base;}
a {outline: none; cursor: pointer}

hr.outsider {
	margin-left: -20px;
	margin-right: -20px;
	border-top: 1px solid @border-lighter;
}

hr.outsider-dashed {
	margin-left: -20px;
	margin-right: -20px;
	border-top: 1px dashed @border-lighter;
}

hr.dashed {
	border-top: 1px dashed @border-darker;
}


.row.no-gutter {
	margin-left: 0;
	margin-right: 0;

 	[class*='col-']:not(:first-child),
	[class*='col-']:not(:last-child) {
		padding-right: 0;
		padding-left: 0;
	}
}

//helper classs
.m0 {margin: 0;}

.mt0 {margin-top: 0;}
.mt5 {margin-top: 5px;}
.mt15 {margin-top: 15px;}
.mt20 {margin-top: 20px;}

.mb0 {margin-bottom: 0;}
.mb5 {margin-bottom: 5px;}
.mb15 {margin-bottom: 15px;}
.mb20 {margin-bottom: 20px;}

.p0 {padding: 0;}

.pt0 {padding-top: 0;}
.pt5 {padding-top: 5px;}
.pt15 {padding-top: 15px;}
.pt20 {padding-top: 20px;}

.pb0 {padding-bottom: 0;}
.pb5 {padding-bottom: 5px;}
.pb15 {padding-bottom: 15px;}
.pb20 {padding-bottom: 20px;}

.w100 {width: 100%;}


//type.less
.text-primary, .text-warning, .text-danger, .text-success, .text-info, .text-gray {
	&:hover {color: inherit;}
}

.text-gray {color: @gray}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	//color: @gray-darker;
	margin-top: 10px;
	margin-bottom: 10px;
	font-family: @headings-font-family;
	//font-weight: @headings-font-weight;
}

h1, h2, h3, .h1, .h2 .h3 {
	
	font-weight: 300;
}

h4, .h4 {
	
	font-weight: 400;
}

h5, h6, .h5, .h6 {
	
	font-weight: bold;
}

h1 small, .h1 small { font-size: ceil(@font-size-base * 1.42857); font-weight: 300;} // ~20px
h2 small, .h2 small { font-size: ceil(@font-size-base * 1.21429); font-weight: 300;} // ~17px
h3 small, .h3 small { font-size: ceil(@font-size-base * 1.00000); font-weight: 400;} // ~14px
h4 small, .h4 small { font-size: ceil(@font-size-base * 0.92857); font-weight: 400;} // ~13px
h5 small, .h5 small { font-size: ceil(@font-size-base * 0.85714); font-weight: 400;} // ~12px
h6 small, .h6 small { font-size: ceil(@font-size-base * 0.78571); font-weight: 400;} // ~11px

p.lead {
	font-size: @font-size-large;
	font-weight: 400;
}

small,
.small  { font-size: 0.85714em; }

.list-inline > li {
	&:first-child {padding-left: 0;}
}

.panel dl    {margin: 0 auto;}
.panel dl dd {margin-bottom:10px;}

blockquote {
	font-style: italic;
	border-left-width: 4px;
	padding: 0 20px;
	&.pull-right {
		border-right-width: 4px;
	}
}

address {display: block;}

//code.less
kdb, samp {
	font-family: @font-family-monospace;
}

pre {
	margin-bottom: 20px;
	&.prettyprint {
    	margin-bottom: 10px; //@line-height-computed;
    }
}

// tables.less

.tabe-responsive .table-bordered, .table-bordered {
	tfoot > tr:last-child > th {border-bottom: 0;}
	tfoot > tr:last-child > th:last-child {border-right: 0;}
	.box-shadow(~"0 0 0 1px #e3e3e3");
	border-radius: @border-radius-base;
	border-style: none;
	> thead,
	> tbody,
	> tfoot {
		> tr {
			> th,
			> td {
				border-left: 0;
		  	}
		  	&:last-child td {border-bottom: 0;}
		}
	}
}
table.fc-border-separate > thead > tr > th:last-child {border-right: 0 !important;}
table.fc-border-separate > tbody > tr > td:last-child {border-right: 0 !important;}

table.table-bordered > thead > tr > th:last-child {border-right: 0 !important;}
table.table-bordered > tbody > tr > td:last-child {border-right: 0 !important;}

table > tbody > tr:first-child > th {border-top: 0;}
table > tbody > tr:first-child > td {border-top: 0;}

table > tbody > tr:last-child > th {border-bottom: 0;}
// table > tbody > tr:last-child > td {border-bottom: 0;}

table > thead > tr > th {
	background: #fff;
	//background-image: linear-gradient(#fff 0%, rgba(255, 255, 255, 0) 100%);
}

table > thead > tr > th {
	
	&:first-child {border-top-left-radius: @border-radius-base;}
	&:last-child {border-top-right-radius: @border-radius-base;}
}

.panel-heading + .panel-body.panel-no-padding {
	table > tbody > tr:last-child > th {border-bottom: 0;}
	table > tfoot > tr:last-child > th {border-bottom: 0;}
	.table-bordered {
		border-bottom: 0 !important;
		.box-shadow(~"0 0 0 0 #e3e3e3") !important;
	}
	table > thead > tr > th {
	
		&:first-child {border-top-left-radius: 0;}
		&:last-child {border-top-right-radius: 0;}
	}
}

table > tbody > tr:last-child > td:first-child {border-bottom-left-radius: @border-radius-base;}
table > tbody > tr:last-child > td:last-child {border-bottom-right-radius: @border-radius-base;}
table > tbody > tr:last-child > th:first-child {border-bottom-left-radius: @border-radius-base;}
table > tbody > tr:last-child > th:last-child {border-bottom-right-radius: @border-radius-base;}

// .table-bordered {
//   border: none;
//   > thead,
//   > tbody,
//   > tfoot {
//     > tr {
//       > th:first-child,
//       > td:first-child {
//         border-left: none;
//       }
//       > th:last-child,
//       > td:last-child {
//         border-right: none;
//       }
//       &:last-child > td {border-bottom: none;}
//     }
//   }
// }

table input[type="text"] {width: 100% !important;}


// Responsive Tables
.table-responsive {
	overflow-x: auto;
}
@media (max-width: @screen-xs-max) {

	/* Wrap your tables in `.table-flipscroll` and we'll fix the header and make the tbody scrollable */
	.table-flipscroll {
		table, thead {
			&:extend(.clearfix all);
		}
		table, caption, td, thead, thead tr, th, tbody {display: block;}

		table { 
			width: 100%; border-collapse: collapse; border-spacing: 0; position: relative; 

			th, td {margin: 0; vertical-align: top; 
				&:last-child {border-bottom: 1px solid @table-border-color;}
			}
			td {min-height: 1.25em; text-align: left; border-left: 0; border-right: 0; border-bottom: 0;}

			thead {float: left;}
			th {text-align: left; border-bottom-width: 1px !important; border-left: 0;
				&:first-child {border-top: 1px solid transparent !important;}
			}

			tbody {width: auto; position: relative; overflow-x: auto; white-space: nowrap; 
				tr  {display: inline-block; vertical-align: top; border-left: 1px solid @table-border-color;}
			}
		}
	}

	/* Force table to not be like tables anymore */
	.table-vertical { 
		table, caption, thead, tbody, th, td, tr { 
			display: block; 
		}

		/* Hide table headers (but not display: none;, for accessibility) */
		thead tr { 
			position: absolute;
			top: -9999px;
			left: -9999px;
		}
		tr { border: 1px solid #ccc; }

		td { 
			/* Behave  like a "row" */
			border: none;
			border-bottom: 1px solid @table-border-color; 
			position: relative;
			padding-left: 50% !important; 
			white-space: normal;
			text-align:left;
			&:before { 
    		    position: absolute; // Now like a table header
    		    top: 6px; // Top/left values mimic padding //
    		    left: 6px;
    		    width: 45%; 
    		    padding-right: 10px; 
    		    white-space: nowrap;
    		    text-align:left;
    		    font-weight: bold;
    		    content: attr(data-title); // Label the data
    		}
		}
	}
}

// .table-hidecolumn {
// 	@media (max-width: @screen-xs-max) {
// 		.table-hide-xs {display:none; visibility:hidden;}
// 		.table-hide-sm {display:none; visibility:hidden;}
// 		.table-hide-md {display:none; visibility:hidden;}
// 	}
// 	@media (max-width: @screen-sm-max) {
// 		.table-hide-xs {display:none; visibility:hidden;}
// 		.table-hide-sm {display:none; visibility:hidden;}
// 	}
// 	@media (max-width: @screen-md-max) {
// 		.table-hide-md {display:none; visibility:hidden;}
		
// 	}
// }

// forms.less
legend {
	padding-bottom: 5px;
	font-weight: 300;
	font-size: @font-size-h3;
}

label {margin-bottom: 10px; font-weight: normal;}

.label-input-lg {font-size: @font-size-large; padding-top: 9px !important;}
.label-input-sm {font-size: @font-size-small; padding-top: 5px !important;}


.checkbox > label, .checkbox-inline > label {margin: 0;}

select[multiple] {
  padding: 7px 9px !important;
}

.form-control {
	border: 1px solid @border-darker;
	border-top: 1px solid darken(@border-darker, 2%);
	padding: 6px 10px;
	color: @gray-dark;
	//height: 34px;
	background: @tone-brighter;
	.box-shadow(inset 0 1px 0px rgba(0,0,0,0.01)) !important;
	select& {
		color: @gray-dark;
		padding: 6px 6px;
	}
	&:focus {
      border: 1px solid @brand-primary;
      background: #fff;
      box-shadow: 0 0px 1px 0px lighten(@brand-primary, 40%) !important;
    }
}

.radio input[type="radio"],
.radio-inline input[type="radio"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
	margin-left: -16px;
}


//select.input {padding: 6px 6px;}
select.input-lg {padding: 6px 12px;}
select.input-sm {padding: 4px 3px;}


.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {

	border: 1px solid @border-lighter;
	border-top: 1px solid darken(@border-lighter, 2%);
	box-shadow: none !important;
}

.input {
	height: 34px !important;
}

.input-sm {
	border-radius: @border-radius-base;
	padding: 4px 7px;
	height: 28px;

}

.input-lg {
	padding: 6px 16px;
	font-size: @font-size-large;
	height: 45px;
}

.form-group {
	margin-bottom: 20px;
	// Use row-border with a form to have both top and bottom 1px separation
	.row-border & {
		border-top: 1px dashed @border-lighter;
		padding: 20px 10px;
		margin-bottom: 0;
		margin-left: -20px;
		margin-right: -20px;
		&:first-child {
			border-top: none;
			padding-top: 0;
		}
	}

	.tabular-form & {
		border-top: 1px dashed @border-lighter;
		padding: 20px 10px;
		margin-left: -20px;
		margin-right: -20px;
		margin-bottom: 0;

		> .tabular-border {
			border-left: 1px dashed @border-lighter;
			margin: -20px 0;
			padding: 20px 10px;
		}

		@media (max-width: @screen-sm) {
			.tabular-border {border-left: none;}
		}

		&:first-child {
			border-top: none;
			padding-top: 0;
		}
		.col-*:first-child {} 
	}
}



select[multiple] {border-radius: @border-radius-base; border: 1px solid @border-darker;}

.help-block {margin-bottom: 0;}
.help-inline {margin-top: 5px; display: inline-block; color: @gray;}


.input-search-icon-sm {
	padding: 20px !important;
	input.form-control {
		padding-right: 25px ; 
	}
	a {
		position: absolute;
		margin-top: -23px;
		font-size: @font-size-small;
		right: 0;
		margin-right: 28px;
		color: @gray-light;
	}
}



.input-icon {
	position: relative;
	> i, .icon {
		position: absolute; 
		display: block;
		margin: 10px 8px; 
		line-height: 14px;
		.text-muted(); 
		//.fa-fw();
		&:hover {text-decoration: none;}
	}
	> .form-control {padding-left: 35px;}
	
	&.right {
		> i, .icon {right: 0px; float: right;}
		> .form-control {padding: 6px 35px 6px 10px;}
		&.input-group > i,  &.input-group > .icon {right: 0;}
	}

	&.input-group.left {> .form-control {padding-left: 30px; .border-left-radius(@border-radius-base)}}
}
.input-icon-sm {
	> i {margin: 7px;}
}


//Border Radius fixes because of first-child issues
.input-group > :first-child > .form-control {
	.border-left-radius(@border-radius-base);
}

.spinner i {
	color: @gray-dark;
}

//Fix alignment oflabels in small screen
@media (max-width: @screen-sm) {
	.form-horizontal {
		.control-label, 
		.radio, .checkbox, 
		.radio-inline, .checkbox-inline {
			padding-top: 0 !important;
			padding-bottom: 7px;
		}
	}
}

.form-horizontal {
	.control-label {
		padding-top: 7px;
		padding-bottom: 7px;
	}
}

//validation states
.has-success {
	.input-icon {
		i, .icon {color: @state-success-text}
	}
}
.has-warning {
  .input-icon {
		i, .icon {color: @state-warning-text}
	}
}
.has-error {
  .input-icon {
		i, .icon {color: @state-danger-text}
	}
}

// buttons.less

.btn-group.open .dropdown-toggle {
	-webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1) !important; 
	box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1) !important;
}

.btn {
	font-weight: 400;
	box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.04);
	border-radius: @border-radius-base;
	

	&:hover {
		
	}

	&:active, &.active{
		background-image: none;
		outline: 0;
		.box-shadow(inset 0 1px 2px rgba(0,0,0,.125));
	}

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
  	.opacity(.5);
  }

}

.btn-rounded {
	border-radius: 50px;
}



.btn-xs {
	font-size: @font-size-mini;
	line-height: 1em;
	padding: 3px 5px;
}

.btn-sm {

	//font-size: 12px;
}

.btn-lg {
	padding: 10px 29px;
	//font-size: 16px;
}


.btn-block {
	padding-left: 15px;
	padding-right: 15px;
}


.btn-link {box-shadow: none !important;}

// custom colors
.btn-inverse {
	.button-variant(#fff, @brand-inverse, #7f8c8d);
}

.btn-brown {
  .button-variant(#fff, @brand-brown, #AC2618);
}
.btn-indigo {
  .button-variant(#fff, @brand-indigo, #8e44ad);
}
.btn-orange {
  .button-variant(#fff, @brand-orange, #d35400);
}
.btn-sky {
  .button-variant(#fff, @brand-sky, #67aed3);
}
.btn-midnightblue {
  .button-variant(#fff, @brand-midnightblue, #2c3e50);
}
.btn-magenta {
  .button-variant(#fff, @brand-magenta, #bd3154);
}
.btn-green {
  .button-variant(#fff, @brand-green, #13856e);
}
.btn-purple {
  .button-variant(#fff, @brand-purple, #c73094);
}
.btn-alizarin {
  .button-variant(#fff, @brand-alizarin, #c23829);
}
.btn-block + .btn-block {
	margin-top: 10px;
}

// Alternate buttons
// --------------------------------------------------

.btn-default-alt {
  .btn-alt-states(@gray-lighter);
  color: @gray-dark;
  &:hover, &:active, &.active, &:focus {color: @gray-darker !important;}
}

.btn-primary-alt      {.btn-alt-states(@btn-primary-bg) ;}
.btn-warning-alt      {.btn-alt-states(@btn-warning-bg) ;}
.btn-danger-alt       {.btn-alt-states(@btn-danger-bg) ;}
.btn-success-alt      {.btn-alt-states(@btn-success-bg) ;}
.btn-info-alt         {.btn-alt-states(@btn-info-bg) ;}
.btn-inverse-alt      {.btn-alt-states(@btn-inverse-bg) ;}

// custom colors
.btn-brown-alt        {.btn-alt-states(@brand-brown) ;}  
.btn-indigo-alt       {.btn-alt-states(@brand-indigo) ;}
.btn-orange-alt       {.btn-alt-states(@brand-orange) ;}
.btn-sky-alt          {.btn-alt-states(@brand-sky) ;}
.btn-midnightblue-alt {.btn-alt-states(@brand-midnightblue) ;}
.btn-magenta-alt      {.btn-alt-states(@brand-magenta) ;}  
.btn-green-alt        {.btn-alt-states(@brand-green) ;} 
.btn-purple-alt       {.btn-alt-states(@brand-purple) ;} 
.btn-alizarin-alt     {.btn-alt-states(@brand-alizarin) ;} 


.btn[class*="alt"] {
  padding: (@padding-base-vertical) (@padding-base-horizontal);  // border makes up for the paddings we are decreasing
  &.btn-label i {.rgba(black,0.02) !important;}
}
.btn-lg[class*="alt"] {
  .button-size((@padding-large-vertical)-1;( @padding-large-horizontal)-1; @font-size-large; @line-height-large; @border-radius-large);
  padding: 10px 29px;
}

.btn-sm[class*="alt"] {
  .button-size((@padding-small-vertical); (@padding-small-horizontal); @font-size-small; @line-height-small; @border-radius-base);
}

.btn-xs[class*="alt"] {
  .button-size((@padding-small-vertical)-1; (@padding-small-horizontal)-1; @font-size-mini; @line-height-small; @border-radius-base);
  padding: 3px 5px; line-height: 1em;
}


.btn-group .btn {
	//&:first-child &:not(:last-child) {
	//	.border-right-radius(0) !important;
	//}
	//&:last-child {.border-right-radius(@btn-border-radius) !important}
	//margin-right: 1px;
}

.btn-group-vertical {
	> .btn + .btn, > .btn + .btn-group, > .btn-group + .btn, > .btn-group + .btn-group {
		//margin-top: 0;
	}
}

.btn-toolbar {
	> .btn:first-child, 
	> .btn-group:first-child, 
	> .input-group:first-child {
		margin-left: 0;
	}
}


.btn>i.pull-left,.btn>i.pull-right {
	line-height: @line-height-computed;
}

.btn-label {
	padding: 0 20px 0 14px !important;

	i {
		padding: 9px;
		line-height: 14px;
		left: -14px;
		width: 40px;
		
		position: relative;
		display: inline-block;
		//background: rgba(0,0,0,.1);
		.rgba(black,0.0);
		border-radius: 3px 0 0 3px;
		box-shadow: inset -1px 0 0 0 rgba(0, 0, 0, 0.1);
	}

	&.btn-block {
		padding-top:    8px !important;
		padding-bottom: 8px !important;
		
		i 		 {top: -9px;}
		i + span {left: -10px; position: relative;}
	}
	&.btn-xs {
		padding: 0 5px 0 2px !important;
		i {
			width: 24px;
			left: -2px;
			line-height: 1em;
			padding: 3px 4px;
		}
	}
	&.btn-sm {
		padding: 0 12px 0 8px !important;
		i {
			left: -8px;
			line-height: 8px;
			width: 32px;
		}
	}
	&.btn-lg {
		padding: 0 29px 0 21px !important;
		i {
			left: -21px;
			line-height: 23px;
			width: 48px;
		}
	}
}


//Social Icons

.btn.btn-social {
	color: #fff;
	padding: 6px 12px;

	&.btn-xs {padding: 3px 4px;}
	&.btn-sm {padding: 4px 6px;}
	&.btn-lg {padding: 10px;}

	&.btn-facebook {.button-variant(#fff, rgb(59,89,152),rgb(59,89,152) );}
    &.btn-twitter {.button-variant(#fff, rgb(0,172,237),rgb(0,172,237))}
    &.btn-github {.button-variant(#fff, rgb(65,131,196),rgb(65,131,196));}
    &.btn-behance {.button-variant(#fff, rgb(23,105,255),rgb(23,105,255));}
    &.btn-flickr {.button-variant(#fff, rgb(0,99,219),rgb(0,99,219));}
    &.btn-jsfiddle {.button-variant(#fff, rgb(70,121,164),rgb(70,121,164));}
    &.btn-reddit {.button-variant(#fff, rgb(255,69,0),rgb(255,69,0));}
    &.btn-youtube {.button-variant(#fff, rgb(205,51,45),rgb(205,51,45));}
    &.btn-foursquare {.button-variant(#fff, rgb(35,152,201),rgb(35,152,201));}
    &.btn-google {.button-variant(#fff, rgb(36,93,193),rgb(36,93,193));}
    &.btn-apple {.button-variant(#fff, rgb(185,191,193),rgb(185,191,193));}
    &.btn-soundcloud {.button-variant(#fff, rgb(255,102,0),rgb(255,102,0));}
    &.btn-steam {.button-variant(#fff, rgb(0,0,0), rgb(0,0,0));}
    &.btn-spotify {.button-variant(#fff, rgb(128,183,25),rgb(128,183,25));}
    &.btn-wordpress {.button-variant(#fff, rgb(33,117,155),rgb(33,117,155));}
    &.btn-skype {.button-variant(#fff, rgb(0,175,240),rgb(0,175,240));}
	&.btn-android {.button-variant(#fff, rgb(164,198,57),rgb(164,198,57));}
	&.btn-digg {.button-variant(#fff, rgb(20,88,158),rgb(20,88,158));}
	&.btn-linkedin {.button-variant(#fff, rgb(0,127,177),rgb(0,127,177));}
	&.btn-googleplus {.button-variant(#fff, rgb(209,72,54),rgb(209,72,54));}
	&.btn-instagram {.button-variant(#fff, rgb(63,114,155),rgb(63,114,155));}
	&.btn-windows {.button-variant(#fff, rgb(0,189,246),rgb(0,189,246));}


	&.btn-facebook-alt 	{.btn-alt-states(rgb(59,89,152));}
    &.btn-twitter-alt 	{.btn-alt-states(rgb(0,172,237));}
    &.btn-github-alt 	{.btn-alt-states(rgb(65,131,196));}
    &.btn-behance-alt 	{.btn-alt-states(rgb(23,105,255));}
    &.btn-flickr-alt 	{.btn-alt-states(rgb(0,99,219));}
    &.btn-jsfiddle-alt 	{.btn-alt-states(rgb(70,121,164));}
    &.btn-reddit-alt 	{.btn-alt-states(rgb(255,69,0));}
    &.btn-youtube-alt 	{.btn-alt-states(rgb(205,51,45));}
    &.btn-foursquare-alt{.btn-alt-states(rgb(35,152,201));}
    &.btn-google-alt 	{.btn-alt-states(rgb(36,93,193));}
    &.btn-apple-alt 	{.btn-alt-states(rgb(185,191,193));}
    &.btn-soundcloud-alt{.btn-alt-states(rgb(255,102,0));}
    &.btn-steam-alt 	{.btn-alt-states(rgb(0,0,0))}
    &.btn-spotify-alt 	{.btn-alt-states(rgb(128,183,25));}
    &.btn-wordpress-alt {.btn-alt-states(rgb(33,117,155));}
    &.btn-skype-alt 	{.btn-alt-states(rgb(0,175,240));}
	&.btn-android-alt 	{.btn-alt-states(rgb(164,198,57));}
	&.btn-digg-alt 		{.btn-alt-states(rgb(20,88,158));}
	&.btn-linkedin-alt 	{.btn-alt-states(rgb(0,127,177));}
	&.btn-googleplus-alt{.btn-alt-states(rgb(209,72,54));}
	&.btn-instagram-alt {.btn-alt-states(rgb(63,114,155));}
	&.btn-windows-alt 	{.btn-alt-states(rgb(0,189,246));}

	//Only for the black steam button
	&.btn-steam.btn-label i {
		.rgba(white,0.15);
		//background: rgba(255,255,255,0.15);
	}
}


// Paginations

.pagination-lg > li {
	> a, > span {padding: 10px 20px !important}
}

.pagination {
	> a,  > span {padding: 6px 14px}
}
.pagination-sm {
	> a,  > span {padding: 4px 12px !important}
}