// Get Colors
// -------------------------

@import "colors/alizarin.less";
@import "colors/danger.less";
@import "colors/default.less";
@import "colors/grape.less";
@import "colors/green.less";
@import "colors/indigo.less";
@import "colors/info.less";
@import "colors/inverse.less";
@import "colors/midnightblue.less";
@import "colors/primary.less";
@import "colors/violet.less";


// Mixin
//--------------------------------
.horizontal-variant(@variant) {

	@horizontal-background: "horizontal-@{variant}-background";
	@horizontal-hover-bg: "horizontal-@{variant}-hover-bg";
	@horizontal-hover-color: "horizontal-@{variant}-hover-color";

	@horizontal-submenu-background: "horizontal-@{variant}-submenu-background";
	@horizontal-submenu-item-color: "horizontal-@{variant}-submenu-item-color";

	@horizontal-submenu-item-hover-bg: "horizontal-@{variant}-submenu-item-hover-background";
	@horizontal-submenu-item-hover-color: "horizontal-@{variant}-submenu-item-hover-color";

	@horizontal-menu-color: "horizontal-@{variant}-menu-color";
	@horizontal-icon-color: "horizontal-@{variant}-icon-color";

	@horizontal-active-bg: "horizontal-@{variant}-active-bg";
	@horizontal-active-color: "horizontal-@{variant}-active-color";


	background: @@horizontal-background;
	//.navbar-header {background: @@horizontal-background; color: @@horizontal-submenu-item-color;}
	.navbar-nav {
		margin-top: 0;
		margin-bottom: 0;
		background: @@horizontal-background;

		> .open > a, > .open > a i {
			background: @@horizontal-hover-bg;
			color: @@horizontal-hover-color;
		}
		ul {
			background-color: @@horizontal-submenu-background;
			border: none;
			border-radius: 0px;
			li a {
				color: @@horizontal-submenu-item-color;
				&:hover {
					background: @@horizontal-submenu-item-hover-bg;
					color: @@horizontal-submenu-item-hover-color;					
				}
			}
		}

		li {
			a {
				color: 				@@horizontal-menu-color;
				i {color: 			@@horizontal-icon-color;}
			}
			&.active a, &.active a i, &.active a:hover, &.active a:hover i {
				background-color: 	@@horizontal-active-bg;
				color: 				@@horizontal-active-color;
			}
			a:hover, a:hover i {
				background: 		@@horizontal-hover-bg;
				color: 				@@horizontal-hover-color;
			}
			a:focus, a:focus i {
				background: 		@@horizontal-hover-bg;
				color: 				@@horizontal-hover-color;
			}
			
			i {margin-right: 2px;}
		}

	}

	.navbar-toggle {
		&, &:hover, &:focus {
			background: transparent;
			color: @@horizontal-menu-color;
			border:none;
		}
	}
}

.sidebar-variant(@variant) {
	@sidebar-divider : "sidebar-@{variant}-divider";

	@sidebar-background : "sidebar-@{variant}-background";
	@sidebar-color : "sidebar-@{variant}-color";
	@sidebar-menu-color : "sidebar-@{variant}-menu-color";
	@sidebar-icon-color : "sidebar-@{variant}-icon-color";

	@sidebar-active-bg : "sidebar-@{variant}-active-bg";
	@sidebar-active-color : "sidebar-@{variant}-active-color";

	@sidebar-hover-bg : "sidebar-@{variant}-hover-bg";
	@sidebar-hover-color : "sidebar-@{variant}-hover-color";

	@sidebar-submenu-bg : "sidebar-@{variant}-submenu-bg";
	@sidebar-submenu-hover-bg : "sidebar-@{variant}-submenu-hover-bg";
	@sidebar-submenu-color : "sidebar-@{variant}-submenu-color";

	@sidebar-disabled-color : "sidebar-@{variant}-disabled-color";

	@sidebar-search-bg : "search-@{variant}-bg";
	@sidebar-search-color : "search-@{variant}-color";

	@sidebar-border-top: "sidebar-@{variant}-border-top";
	@sidebar-submenu-open: "sidebar-@{variant}-submenu-open";


	&.static-sidebar-wrapper, &.fixed-sidebar {background: @@sidebar-background;}

	.sidebar {
		.widget {
			.widget-heading {
				border-bottom: 1px solid @@sidebar-border-top;
				color: @@sidebar-disabled-color;
				background: @@sidebar-divider;
			}
			.widget-body {
				color: @@sidebar-color;
				background: @@sidebar-background;
				h5, h6 {color: @@sidebar-color;}
			}
			.widget-block {
				border-bottom: 1px solid @@sidebar-border-top;
				color: @@sidebar-color;
				h5, h6 {color: @@sidebar-color;}
			}
		}
	}

	.sidebar .contextual-progress {
		border-bottom: 1px solid @@sidebar-border-top;
		.progress-title, .progress-title a, .progress-percentage {color: @@sidebar-menu-color;}
		.progress-title a:hover {color: @@sidebar-hover-color;}
	}

	.sidebar .contactdetails {
		.contact-status {
			font-size: @font-size-small;
			color: @@sidebar-color;
		}
		ul.details {
			li {
				background: @@sidebar-divider;
				color: @@sidebar-menu-color;
				&:hover {color: @@sidebar-hover-color;}
			}
		}
	}

	#search {
		form {			
			.search-query {
				background: @@sidebar-background;
				color: @@sidebar-search-color;
				.placeholder(fade(@@sidebar-search-color, 50%));
			
				&:focus {
			 		.placeholder(~"transparent");
					+ button i {.opacity(1);}
				}

				&, &:active, &:focus {
					border: none;
				}
			}
			
			button[type="submit"] {
				i {color: @@sidebar-search-color; .opacity(0.5)}
			}
		}
	}

	.search-toggler {
		i {color: @@sidebar-icon-color;}
	}

	#sidebar-search {
		form {
			input[type='text'] {
				.placeholder (@@sidebar-color);
				background: @@sidebar-background;
			}
		}
	}

	.jstree .jstree-container-ul {
		li a.jstree-anchor {
			color: @@sidebar-menu-color;
			&:hover {color: @@sidebar-hover-color;}
		}
	}

	nav.widget-body > ul.acc-menu {

		.divider, .divider:hover {
			background-color: @@sidebar-divider;
		}

		ul, ul li a {background: @@sidebar-submenu-bg; color: @@sidebar-submenu-color;}

		//Main Menu Styles - Level 1 
		li a {color: @@sidebar-menu-color; background: @@sidebar-background; border-top: 1px solid @@sidebar-border-top;}
		>li:hover>a {background-color: @@sidebar-hover-bg; color: @@sidebar-hover-color;}
		>li.active>a {background: @@sidebar-active-bg; color: @@sidebar-active-color;}

		// Level 1 Icon styles
		> li > a > i {color: @@sidebar-icon-color; }
		> li > a:hover > i {color: @@sidebar-hover-color;}
		> li.active > a > i, > li.active > a:hover > i {color: @@sidebar-active-color;}


		// Submenu Styles - Level 2
		ul li a {border-top: 1px solid transparent} //
		ul li a:hover {background-color: @@sidebar-submenu-hover-bg; color: @@sidebar-hover-color;}
		ul li.active:not(.open)>a {color: @@sidebar-submenu-open;}
		
		li.disabled-link a {
	   		color: @@sidebar-disabled-color;
		}
	}
}

.topnav-variant(@variant) {

	@topnav-color: "topnav-@{variant}-color";
	@topnav-bg-color: "topnav-@{variant}-bg-color";
	@topnav-border-color: "topnav-@{variant}-border-color";

	@topnav-brand-color: "topnav-@{variant}-brand-color";
	@topnav-brand-hover-color: "topnav-@{variant}-brand-hover-color";
	@topnav-brand-hover-bg: "topnav-@{variant}-brand-hover-bg";

	@topnav-link-color: "topnav-@{variant}-link-color";
	@topnav-link-hover-color: "topnav-@{variant}-link-hover-color";
	@topnav-link-hover-bg: "topnav-@{variant}-link-hover-bg";
	@topnav-link-active-color: "topnav-@{variant}-link-active-color";
	@topnav-link-active-bg: "topnav-@{variant}-link-active-bg";
	@topnav-link-disabled-color: "topnav-@{variant}-link-disabled-color";
	@topnav-link-disabled-bg: "topnav-@{variant}-link-disabled-bg";

	@topnav-dropdown-bg: "topnav-@{variant}-dropdown-bg";
	@topnav-dropdown-color: "topnav-@{variant}-dropdown-color";
	@topnav-dropdown-link-color: "topnav-@{variant}-dropdown-link-color";
	@topnav-dropdown-link-hover-color: "topnav-@{variant}-dropdown-link-hover-color";
	@topnav-dropdown-link-hover-bg: "topnav-@{variant}-dropdown-link-hover-bg";



	background-color: @@topnav-bg-color;
	border-color: @@topnav-border-color;

	.navbar-brand {
		color: @@topnav-brand-color;
		&:hover,
		&:focus {
			color: @@topnav-brand-hover-color;
			background-color: @@topnav-brand-hover-bg;
		}
	}

	.navbar-text {
		color: @@topnav-color;
	}

	&.navbar .navbar-collapse {
		border-color: @@topnav-border-color;
		.open .dropdown-menu {
			background-color: @@topnav-dropdown-bg;
			li a {
				color: @@topnav-dropdown-link-color;
				&:hover, &:focus {
					background-color: @@topnav-dropdown-link-hover-bg;
					color: @@topnav-dropdown-link-hover-color;
				}
			}
			.divider {
				border-bottom: 1px solid @@topnav-dropdown-link-hover-bg;
			}
		}
	}

	.navbar-nav {
		> li > a {
			color: @@topnav-link-color;

			&:hover,
			&:focus {
				color: @@topnav-link-hover-color;
				background-color: @@topnav-link-hover-bg;
			}
		}

		> .active > a {
			&,
			&:hover,
			&:focus {
				color: @@topnav-link-active-color;
				background-color: @@topnav-link-active-bg;
			}
		}

		> .disabled > a {
			&,
			&:hover,
			&:focus {
				color: @@topnav-link-disabled-color;
				background-color: @@topnav-link-disabled-bg;
			}
		}

		> .open > a {
			&, &:hover, &:focus {
				background-color: @@topnav-link-active-bg;
				color: @@topnav-link-active-color;
			}
		}
	}

		
	.dropdown-menu, .yamm .dropdown-menu {
		@media (max-width: @grid-float-breakpoint+1) {
			box-shadow: 0 3px 1px 0 rgba(0, 0, 0, 0.1);
		}

		background: @@topnav-dropdown-bg;

		li a {
			color: @@topnav-dropdown-link-color;
			&:hover, &:focus {
				background-color: @@topnav-dropdown-link-hover-bg;
				color: @@topnav-dropdown-link-hover-color;
			}
		}

		&.userinfo {
			background: @@topnav-dropdown-bg;
			color: @@topnav-dropdown-color;
			border: none;
			box-shadow: 0 3px 1px rgba(0, 0, 0, 0.1);

			.username a {
				color: @@topnav-dropdown-link-color;
			}

			a {color: @@topnav-dropdown-link-color;}
			a:hover {
				background: @@topnav-dropdown-link-hover-bg;
				color: @@topnav-dropdown-link-hover-color;
			}
		}
		.divider {
			border-bottom: 1px solid @@topnav-dropdown-link-hover-bg;
		}
	}

	.yamm {
		ul li, ul li a {
			color: @@topnav-link-color;
		}

		.yamm-content {
			color: @@topnav-dropdown-color;
			ul li a {
				color: @@topnav-dropdown-link-color; 
				&:hover {color: @@topnav-dropdown-link-hover-color;}				
			}
			h2, h3, h4 {&.yamm-category {color: @@topnav-dropdown-link-hover-color;}}
		}

		// if row has .yamm-col-bordered, add a border separator
		.row.yamm-col-bordered>.yamm-col:not(:last-child) {border-right: 1px solid @@topnav-dropdown-link-hover-bg;}
	}

	#navbar-links-toggle {
		background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAABkCAYAAADE6GNbAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6QjBGQjlFRjZFMEIxMTFFM0FFMTBFNjU2MDZDMEYwREUiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6QjBGQjlFRjdFMEIxMTFFM0FFMTBFNjU2MDZDMEYwREUiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpCMEZCOUVGNEUwQjExMUUzQUUxMEU2NTYwNkMwRjBERSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpCMEZCOUVGNUUwQjExMUUzQUUxMEU2NTYwNkMwRjBERSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuKbOvoAAACQSURBVHja7NXBCQAhDABBU601WW1swJeCd+rkb2BYgpGZ5YYJEBAQEBAQEBAQEBAQEBCQ0bTF91URkEcgbfdtKAJyEGT2L6mKgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgHwKWV0SioA8Asndt6EIyEGQ2UWhCAgICAgICAgICAgICAgIyO+nCzAAF7ExSJFDKiwAAAAASUVORK5CYII=);
	}
	#leftmenu-trigger, #rightmenu-trigger {
		background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RjJEREE0NzVFMEIyMTFFM0E2QzREMDM5Q0QyOTI5QzEiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RjJEREE0NzZFMEIyMTFFM0E2QzREMDM5Q0QyOTI5QzEiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpGMkREQTQ3M0UwQjIxMUUzQTZDNEQwMzlDRDI5MjlDMSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpGMkREQTQ3NEUwQjIxMUUzQTZDNEQwMzlDRDI5MjlDMSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Ppm9f9oAAAD7SURBVHja7NxRCoMwEEVRI+7VNbnauAF/AiHzIud+l8J4ygyF0tZ7P5TT6REAERAgAgJEQIAICBABERAgAgJEQIAICBCldE16n2fgtfdmz2jpbM2PHKwsAXFDRku5K+VzuCFWloC4Ib/4PlIykxtiZQkIEAEBIiBABASIgAgIEAEBIiBABASIgAgIEAEBIiBABASIgAgIEAEBIiBABASIgAgIEAHZuFn/5DDy7wNts2e0dLZZIO3HH9qls1lZbojSbkjyyiufww0Jm8PKckOUekPS11zJTG5I2ExWlhsiIEAEBIiAABEQIAIiIEAEBIiAABEQIAKi714BBgCTKxjRt20bWAAAAABJRU5ErkJggg==);
	}
}




// Sidebar Nav Variants

.sidebar-default{
	.sidebar-variant(~"default");


	border-right: 1px solid @border-darker;
	background: @sidebar-default-background;

	.sidebar {
		border-right: 1px solid @border-darker;

		.welcome-box {
			.welcome-options .name  {color: @gray-darker;}
			.welcome-edit .btn-link {
				color: rgba(0, 0, 0, 0.3) !important;
				&:hover {color: @gray-darker !important;}
			}
		}

		#sidebar-search {
			form input[type='text'] {
				.placeholder (rgba(0, 0, 0, 0.4));
				background: @sidebar-default-background;
				color: @gray-darker;
			}
		}

		.widget {

			.widget-heading {
				background: @sidebar-default-divider;
				color: darken(@border-darker, 15%);
				border-bottom: 1px solid @sidebar-default-border-top;
			}

			.widget-body {
				color: @gray;
				background: @sidebar-default-background;
				h1, h2, h3, h4, h5, h6 {color: @gray-darker;}
			}

			.widget-block {
				color: @gray;
				border-bottom: 1px solid @sidebar-default-border-top;
				h5 {color: @gray;}
				.big {color: @gray-darker;}
			}
		}


		/* Sidebar Menu */ 

		#search form {
			.search-query {
				background: @search-default-bg;
				color: @search-default-color;
				.placeholder(fade(@search-default-color,50%));
			}
			
			button[type="submit"] {
				i {color:@search-default-color;.opacity(0.5)}
			}
		}

		.project-switcher {
			.dropdown-menu {
				//background-color: @border-lighter;

				.divider {
					background-color: rgba(0, 0, 0, 0.04);
				}

				li > a {
					color: @gray;
					&:hover {color: @gray-darker;}
				}
			}
		}


		.contactdetails {
			margin-right: auto;
			padding: 20px;

			.avatar {
				width: 50px;
				height: 50px;
				margin-left: -25px;
				left: 50%;
				position: relative;
				margin-bottom: 10px;
			}

				.contact-name {
				font-size: @font-size-large;
				color: @gray-dark;
				text-align: center;
				display: block;
			}
			
			.contact-status {
				font-size: @font-size-small;
				color: @gray-light;
				text-align: center;
				display: block;
				margin-bottom: 20px;
			}

			ul.details {
				.list-unstyled();
				margin-bottom: 0;
				text-align: left;
				font-size: @font-size-base;
				li {
					background: #fff;
					border-radius: @border-radius-base;
					color: @gray;
					padding: 5px;
					margin-bottom: 2px;

					&:last-child {margin-bottom: 0;}
					&:hover {color: @gray-dark;}

					i {
						float: right;
						margin-top: 2px;
					}

					a {
						&:hover {
							text-decoration: none;
						}
					}
				}
			}
		}

	}
}

.sidebar-inverse{
	.sidebar-variant(~"inverse")
}

.sidebar-midnightblue {
	.sidebar-variant(~"midnightblue")
}

.sidebar-grape {
	.sidebar-variant(~"grape")
}

.sidebar-primary {
	.sidebar-variant(~"primary")
}

.sidebar-alizarin {
	.sidebar-variant(~"alizarin")
}

.sidebar-indigo {
	.sidebar-variant(~"indigo")
}

.sidebar-green {
	.sidebar-variant(~"green")
}

.sidebar-info {
	.sidebar-variant(~"info")
}

.sidebar-violet {
	.sidebar-variant(~"violet")
}




// Horizontal Nav variants

#headernav {
	&.navbar-default {
		.horizontal-variant(~"default");
	}

	&.navbar-midnightblue {
		.horizontal-variant(~"midnightblue");
	}

	&.navbar-inverse {
		.horizontal-variant(~"inverse");
	}

	&.navbar-green {
		.horizontal-variant(~"green");
	}

	&.navbar-info {
		.horizontal-variant(~"info");
	}

	&.navbar-grape {
		.horizontal-variant(~"grape");
	}

	&.navbar-primary {
		.horizontal-variant(~"primary");
	}

	&.navbar-alizarin {
		.horizontal-variant(~"alizarin");
	}

	&.navbar-indigo {
		.horizontal-variant(~"indigo");
	}

	&.navbar-violet {
		.horizontal-variant(~"violet");
	}
}

// Topnav
#topnav {
	&.navbar-primary {
		.topnav-variant(~"primary");
	}

	&.navbar-green {
		.topnav-variant(~"green");
	}

	&.navbar-alizarin {
		.topnav-variant(~"alizarin");
	}

	&.navbar-danger {
		.topnav-variant(~"danger");
	}

	&.navbar-grape {
		.topnav-variant(~"grape");
	}

	&.navbar-inverse {
		.topnav-variant(~"inverse");
	}

	&.navbar-violet {
		.topnav-variant(~"violet");
	}

	&.navbar-indigo {
		.topnav-variant(~"indigo");
	}

	&.navbar-info {
		.topnav-variant(~"info");
	}

	&.navbar-midnightblue {
		.topnav-variant(~"midnightblue");
	}

	&.navbar-default {

		.dropdown-menu {
			background: @navbar-default-link-active-bg;
			color: @gray-dark;
			&.userinfo {
				background: @navbar-default-link-active-bg;
				color: @gray;
				border: 1px solid @border-darker;
				border-top: none;
				a {color: @gray-dark;}
				a:hover {
					background: @border-lighter;
					color: @gray-darker;
				}
			}
			.divider {
				border-bottom: 1px solid @border-lighter;
			}
		}

		.navbar-nav .open .dropdown-menu > li > a {
			color: @gray-dark;
			&:hover {
				color: @gray-darker;
				background: @dropdown-link-hover-bg;
			}
		}	



		.yamm {

			//.yamm-content h6 {border-bottom: 1px solid rgba(0, 0, 0, 0.06);}
			.yamm-content h6 .yamm-category {
				color: @gray;
			}
			.yamm-content ul li, 
			.yamm-content ul li a {
				color: @gray-dark;
			}

			.dropdown-menu {
				border: 1px solid @border-darker;
				border-top: none;
				ul li, ul li a, li, li a {
					color: @gray-dark;
				}
				ul li:hover {
					background: @dropdown-link-hover-bg;
					a {color: @gray-darker;}
				}
				li a:hover {
					background: @dropdown-link-hover-bg;
					color: @gray-darker;
				}
			}

			// if row has .yamm-col-bordered, add a border separator
			.row.yamm-col-bordered>.yamm-col:not(:last-child) {border-right: 1px solid @border-lighter;}
		}

		#navbar-links-toggle {
			background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAABkCAYAAADE6GNbAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6QkQ2NEZDRENFMEIxMTFFMzlGNEZGMjJFQzU2OTAyMDMiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6QkQ2NEZDRERFMEIxMTFFMzlGNEZGMjJFQzU2OTAyMDMiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpCRDY0RkNEQUUwQjExMUUzOUY0RkYyMkVDNTY5MDIwMyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpCRDY0RkNEQkUwQjExMUUzOUY0RkYyMkVDNTY5MDIwMyIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PjkAYMwAAAClSURBVHja7NpLEcAwCAXA4imaKiqa6inRkM8hDYsCdt4wcCBaa88NFSAgICAgICAgICAgICAgeyARsaWR5T42Qd5FRz0Fck0iIKdCRmelSgQkEWR2l1SJuLVAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH4Gue3z4Vt0FJ8PIEkgo7NSJAKSCDK7S4pE3FogICAgICAgICAgICAgIMdXF2AAwhJzSF8qHmUAAAAASUVORK5CYII=);
		}

		#leftmenu-trigger, #rightmenu-trigger {
			background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MDBBNkJFOTJFMEIzMTFFMzk0MzlBQjYyNzBGNDNDMUQiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MDBBNkJFOTNFMEIzMTFFMzk0MzlBQjYyNzBGNDNDMUQiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDowMEE2QkU5MEUwQjMxMUUzOTQzOUFCNjI3MEY0M0MxRCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDowMEE2QkU5MUUwQjMxMUUzOTQzOUFCNjI3MEY0M0MxRCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PmVhaN0AAAEQSURBVHja7NzBDYMwDEBRshMzMRQzsVOYwcFWjPT+narh4UQc2jHnPNSnAQSIgAARECACAkRAgLgLQAQEiIAAERAgAgLkwweMkfJFdj8YXdaRBXIFLrl/BhJaWxcQE9JsQoAAAZJ1hpTsvUkg29dhQmxZQIA4Q+LvIw1BltZkQkyISh8MIEAEBIiAABEQIAICxF0AIiBABASIgAARECACIiBABASIgAARECACIiBABASIgAARECAComwQv1PPXUcWyBO45PwZSGht/snBhAABAqT0DCnZe5NAtq/DhNiygABxhsTfRxqCLK3JhJgQlT4YQIAICBABASIgQAQEiLsARECACAgQAQEiIEDUpVeAAQBZcbtIRXCCjAAAAABJRU5ErkJggg==);
		}
	}
}