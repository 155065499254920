//
// Avalon
// (c) The Red Team
//
// The Theme Rightbar
// --------------------------------------------------

.infobar-wrapper {
	position: fixed;
	z-index: @zindex-navbar - 10;
	top: 0px;
	right: 0;
	bottom: 0;
	height: 100%;
	width: 260px;

	border-left: 1px solid @border-darker;
	background: @body-bg;
	z-index: @zindex-navbar - 1;

	.backface-visibility(~"hidden");
	&:extend(.scrollbar-custom all);
}


.infobar-options {
	&:extend(.clearfix all);
	padding: 8px 20px;
	h2 {float: left; margin: 0; font-size: 18px; line-height: 34px; color: @gray;}
	> a.btn {float: right; color: #666}
}



// Position the Infobar

body {

	&.infobar-overlay {
		.infobar-wrapper {
			.translate(260px,50px);
		}

		&.infobar-active .infobar-wrapper {
			.translate(0px,50px)
		}
	}


	&.infobar-offcanvas {
		.infobar-wrapper {
			.translate(260px,0px);
		}

		&.infobar-active {
			#wrapper, > header {.translate(-260px,0px)}
			> .infobar-wrapper {.translate(0px,0px)}
		}
	}

	&.infobar-overlay .infobar-wrapper, 
	&.infobar-offcanvas .infobar-wrapper, 
	&.infobar-offcanvas #wrapper,
	&.infobar-offcanvas #topnav {
			   transition: transform 0.3s;
	  		-o-transition: -o-transform 0.3s;
	  	   -ms-transition: -ms-transform 0.3s;
	   -webkit-transition: -webkit-transform 0.3s;
	}

}

#layout-fixed .infobar-wrapper {
	.translate(0px,0px);
	border-left: 0;
}                          

#layout-fixed .sidebar, #layout-fixed .sidebar-default {
	border-right: 0;
}



// Internal Infobar Stylings

.infobar {

	#widgetarea {
		display: block;
		overflow: visible;
	}

	.widget-heading {
		background-color: @border-lighter;
		border-bottom: 1px solid darken(@border-lighter, 3.5%);
		
		a {text-decoration: none; color: inherit;
			h4 {
				display: block;
				margin: 0;
				padding: 14px 20px;
				font-weight: 400;
				font-size: @font-size-mini;
				color: lighten(@gray, 3.5%);
				line-height: 1em;
				letter-spacing: 0.1em;
				text-transform: uppercase;
				&:after {
					.opacity(0.5);
					content:"\f107";
					font-family: FontAwesome;
					font-style: normal;
					font-weight: normal;
					float: right;
				}
			}
			&.collapsed h4:after {content:"\f104";.opacity(0.5)}
		}
	}

	.widget-body {
		padding: 0 20px;
		&:extend(.clearfix all);
		//color: @gray;

		.more {
			padding-top: 0px;
			font-size: @font-size-small;
			float: right;
		}

		
		.widget-block {
			&:extend(.clearfix all);
			 padding: 10px;
			 margin-bottom: 10px;
			 border-radius: @border-radius-small;
			 small {
			 	display: block;
			 	color: #fff;
			 	color: rgba(255,255,255,0.75)
			 }
			 h5 {
			 	margin: 0;
			 	color: #fff;
			 	font-size: 1.3em;
			 	>small {vertical-align: super;display: inline;font-size: 0.6em;}
			 }
		}
	}

	

}

#storagespace {

	.progress-title {
		margin-bottom: 5px;
		font-weight: 400;
	}
	.progress {
		margin: 0;
	}
}


/* Style of the Chat area */

.chat-users {
	.list-unstyled();
	margin: 20px 0; padding: 0;

	a {display: block; color: @gray-dark; padding: 5px 20px; margin: 0 -20px;}
	a:hover {text-decoration: none; background-color: @border-lighter;}
	li {margin: 0;}
	li:first-child {margin-top:0;}

	img {width: 24px; height: 24px; border-radius: 50%; vertical-align: top;}
	span {padding-left: 10px; line-height: 24px;}

	li a:after {
		content:"•";
		float: right;
		font-size: 22px;
		margin-top: -5px;
	}

	li[data-stats="online"] a:after 	{color: @brand-success;}
	li[data-stats="busy"] a:after 		{color: @brand-danger;}
	li[data-stats="away"] a:after 		{color: @brand-warning;}
	li[data-stats="offline"] a:after 	{color: @border-lighter;}
}

.chat-area {
	padding: 0 20px;
	.chat-user {
		padding: 10px 0;
		&:extend(.clearfix all);
		#hidechatbtn {
			float: left;
		}
		.user {
			float: right;
			&:extend(.clearfix all);
			.name {
				float: left;
				padding: 4px 0;
			}
			.avatar {
				height: 28px;
				width: 28px;
				border-radius: 50%;
				margin-left: 10px;
				float: right;
			}
		}
	}
	.chat-history {
		margin-bottom: 80px;
		height: auto;
		display: block;
		z-index: 999999999;
		overflow-y: scroll;
		overflow-x: none;
		.scrollbar-custom();

		.chat-msg {
			text-align: right;
			position: relative;
			background: @brand-default;
			color: @gray-dark;
			font-size: @font-size-small;
			width: 100%;
			padding: 10px;
			margin: 10px 0 20px;
			border-radius: @border-radius-base;
			&:after {
				content: "";
				height: 0;
				width: 0;
				position: absolute;
				pointer-events: none;
				top: 100%;
				right: 5%;
				border-top: 10px solid @brand-default;
				border-left: 15px solid transparent;
			}
			.timestamp {
				color: @gray-light;
			}
			&.sent {
				text-align: left;
				background: @brand-midnightblue;
				color: #fff;
				position: relative;
				border-radius: @border-radius-base;
				&:after {
					left: 5%;
					right: auto;
					border-left: none;
					border-top: 10px solid @brand-midnightblue;
					border-right: 15px solid transparent;
				}
				.timestamp {
					color: rgba(255, 255, 255, 0.3);
				}
			}
		}
		.timestamp {
			font-size: @font-size-mini;
		}
	}
	.chat-input {
		height: 100px;
		width: 218px;
		bottom: 30px;
		position: absolute;
		background-color: transparent;
		textarea {
			width: 100%;
			height: 62px;
			padding: 10px;
			resize: none;
		}
	}
}



.contactdetails {
	margin-right: auto;
	padding: 20px 0;

	.avatar {
		width: 50px;
		height: 50px;
		margin-left: -25px;
		left: 50%;
		position: relative;
		margin-bottom: 10px;
	}

		.contact-name {
		font-size: @font-size-large;
		color: @gray-dark;
		text-align: center;
		display: block;
	}
	
	.contact-status {
		font-size: @font-size-small;
		color: @gray-light;
		text-align: center;
		display: block;
		margin-bottom: 20px;
	}

	ul.details {
		.list-unstyled();
		margin-bottom: 0;
		text-align: left;
		font-size: @font-size-base;
		li {
			background: #fff;
			border-radius: @border-radius-base;
			color: @gray;
			padding: 5px;
			margin-bottom: 2px;

			&:last-child {margin-bottom: 0;}
			&:hover {color: @gray-dark;}

			i {
				float: right;
				margin-top: 2px;
			}

			a {
				&:hover {
					text-decoration: none;
				}
			}
		}
	}
}

// settings


ul.settings {
	.list-unstyled();
	padding: 0;
	margin: 0 -20px;
	li {
		&:extend(.clearfix all);
		border-bottom: 1px solid @border-lighter;
		padding: 10px 20px;
	}
}

// sparkline stats

#sparklinestats {
	ul.sparklinestats {
		.list-unstyled();
		margin: 0;
		padding: 0;
		li {
			&:extend(.clearfix all);
			border-bottom: 1px solid @border-lighter;
			margin: 0 -20px;
			padding: 17px 20px;
			.title {
				margin: 0 0 2px;
				font-size: @font-size-base;
				font-weight: 400;
				color: @gray;
			}
			h3 {
				margin: 0;
				color: @gray-dark;
				.badge {
					position: relative;
					top: -3px;
					left: 3px;
				}
			}
			.sparkline {
				margin-top: 25px;
			}
			&:last-child {
				border-bottom: 0;
			}
		}
	}
}
