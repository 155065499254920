/* Drop Downs */

header {
	.toolbar .dropdown, #headerbardropdown, #rightmenu-trigger {
		border-left: 0px solid @navbar-vertical-separator;
	}
	#leftmenu-trigger {
		border-right: 0px solid @navbar-vertical-separator;
	}
	.dropdown-menu > li > a {padding: 5px 18px !important;}
}

.scrollthis {
	&:extend(.scrollbar-custom all);
	
	height: 280px;
	li {
	     &:extend(.clearfix all);
	     a {display: block; text-decoration: none;}
	}
}

header .toolbar .dropdown-menu {
	&.notifications, &.messages, &.taskprogresses, &.search, &.tabbed, &.language-switcher {
		background: #fff !important;
		color: @gray !important;
		padding: 0; 
		z-index: 1300;
		box-shadow: 0 3px 1px rgba(0, 0, 0, 0.1);
		border-top-left-radius: @border-radius-base !important;
		border-top-right-radius: @border-radius-base !important;
		border: 1px solid @dropdown-border;

		width: 314px !important;

		@media (max-width: 480px ) {
			width: 280px !important;
		}

		.dd-header, .dd-footer {
			line-height: 16px;
			a {color: @link-color;}
		}
		
		.dd-header {
			padding: 7px 10px; .clearfix; font-size: @font-size-mini; border-bottom: 1px solid @border-darker; box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.04);
			span:first-child {.pull-left; font-weight: 400; text-transform: uppercase; letter-spacing: 0.1em;}
			span:last-child {
				.pull-right;
				a {padding: 0 !important;}
			}
			a {background: none !important}
			a:hover {color: @link-hover-color}
		}
		.dd-footer {
			font-size: @font-size-mini; padding: 7px 10px; text-align: right; border-top: 1px solid @border-darker; box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0.04);
		}

		ul {.list-unstyled();}

		.scrollthis a {
			white-space: normal; color: @gray-dark !important;
			&.active, &:hover {
				color: @gray-dark !important; 
				.rgba(black,0.03) !important;

				.box-shadow(~"0 1px 0 0 rgba(0,0,0,0.04), 0 -1px 0 0 rgba(0,0,0,0.04)"); 
				.time{color: @gray-light}}
		}

		li {
			border-bottom: 1px solid @tone-bright-alt;
		
			.time {color: @gray-lighter; font-size: @font-size-mini; white-space: nowrap; text-align: right; vertical-align: top;}
			a:focus {background-color: #fff !important;}

		}
	}

	&.taskprogresses, .tab-pane.taskprogresses {
		li {
			.progress {height: 4px; margin-top: 6px;} .progress-title {color: @gray-dark; font-weight: 700;} .progress-desc {color: @gray;} .progress-percentage {color: @gray;}
			&.new {
				.rgba(@brand-info, 0.01);
			}
			a {
				padding: 15px;
			}
		}
	}


	&.notifications, .tab-pane.notifications {

		ul li {
			width: 100%;

			.notification-success 		.notification-icon {background: lighten(@brand-success, 49%); border-color: lighten(@brand-success, 24%); color: @brand-success;}
		 	.notification-primary   	.notification-icon {background: lighten(@brand-primary, 42%); border-color: lighten(@brand-primary, 20%); color: @brand-primary;}
		 	.notification-danger    	.notification-icon {background: lighten(@brand-danger, 40%); border-color: lighten(@brand-danger, 20%); color: @brand-danger;}
		 	.notification-warning   	.notification-icon {background: lighten(@brand-warning, 45%); border-color: lighten(@brand-warning, 22%); color: @brand-warning;}
		 	.notification-inverse   	.notification-icon {background: lighten(@brand-inverse, 30%); border-color: lighten(@brand-inverse, 15%); color: @brand-inverse;}
		 	.notification-info      	.notification-icon {background: lighten(@brand-info, 48%); border-color: lighten(@brand-info, 28%); color: @brand-info;}
		 	.notification-midnightblue  .notification-icon {background: lighten(@brand-midnightblue, 63%); border-color: lighten(@brand-midnightblue, 40%); color: @brand-midnightblue;}

			&:hover {
				.notification-success    .notification-icon {background: @brand-success !important; border-color: lighten(@brand-success, 24%); color: lighten(@brand-success, 49%);}
			 	.notification-primary    .notification-icon {background: @brand-primary !important; border-color: lighten(@brand-primary, 20%); color: lighten(@brand-primary, 42%);}
			 	.notification-danger     .notification-icon {background: @brand-danger !important; border-color: lighten(@brand-danger, 20%); color: lighten(@brand-danger, 40%);}
			 	.notification-warning    .notification-icon {background: @brand-warning !important; border-color: lighten(@brand-warning, 22%); color: lighten(@brand-warning, 45%);}
			 	.notification-inverse    .notification-icon {background: @brand-inverse !important; border-color: lighten(@brand-inverse, 15%); color: lighten(@brand-inverse, 30%);}
			 	.notification-info    	 .notification-icon {background: @brand-info !important; border-color: lighten(@brand-info, 28%); color: lighten(@brand-info, 48%);}
			 	.notification-midnightblue .notification-icon {background: @brand-midnightblue !important; border-color: lighten(@brand-midnightblue, 40%); color: lighten(@brand-midnightblue, 63%);}
			}

			&.new {
				.rgba(@brand-info, 0.01);
			}

			a {
				width: 100%;
				height: 100%;
				display: table;
				&:extend(.clearfix all);
				padding: 12px 38px 12px 55px;
				position: relative;

				.notification-icon {
					position: absolute !important;
					left: 15px;
					top: 16px;

					width: 30px;
					height: 30px;
					border-width: 0px;
					border-style: solid;
					position: relative;
					text-align: center;
					border-radius: 50%;
					line-height: 30px;
				}

				.notification-content {
					vertical-align: middle;
					display: table-cell;
					height: 38px;
				}

				.notification-time {
					display: inline; 
					font-size: @font-size-mini; 
					color: @gray-lighter;
					float: right;
					text-align: right;

					position: absolute !important;
					right: 15px;
					top: 12px;
				}
				&:hover {.notification-time{color: @gray-light}}
			}

		}
	}

	&.messages, .tab-pane.messages {
		ul li {
			&.new {
				.rgba(@brand-info,0.01);
			}

			a {
				&:extend(.clearfix all);
				padding: 12px 38px 12px 55px;
				position: relative;



				img.msg-avatar {
					position: absolute !important;
					left: 15px;
					top: 16px;

					height: 30px;
					width: 30px;

					border-radius: 50%;
				}

				.msg-content {
					min-height: 38px;
					.msg {
						display: block;
						font-size: @font-size-small;
						color: @gray;
						width: 100%;
						float: left;
					}

					.name {
						display: block;
						width: 100%;
						float: left;
						font-weight: 700;
						color: @gray-dark;
						i.attachment {color: @gray-light; margin-left: 5px;}
					}
				}

				.msg-time {
					position: absolute !important;
					top: 12px;
					right: 15px;

					font-size: @font-size-mini; 
					color: @gray-lighter;
				}
				&:hover {.msg-time{color: @gray-light}}
			}
		}
	}

	&.userinfo {
		border-top-right-radius: 0px !important;
		border-top-left-radius: 0px !important;
		padding: 8px 0px;
		width: 220px;
		box-shadow: 0 3px 1px rgba(0, 0, 0, 0.1);

		a {
			&:extend(.clearfix all);
			i, span {line-height: 20px;}
		}
	}
	&.search {
		.input-group {
			padding: 15px;
		}
		
	}
}

.dropdown-tab-btns {
	border-bottom: 1px solid @border-darker;
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.06);
	padding: 7px 10px;
}


.toolbar.navbar-nav           {margin: 0;}
.navbar-nav .dropdown-toggle  {font-size:@font-size-base;}
.navbar-nav.toolbar>li        {
	float: left; 
	>a {
		padding: 15px 16px; 
	}
}

// .toolbar-icon-bg 
.toolbar-icon-bg > a:hover,
.toolbar-icon-bg > a:focus,  
.toolbar-icon-bg > a.active {background: none !important;}
.toolbar-icon-bg.open > a span.icon-bg {background-color: rgba(255,255,255,0.3) !important; &:hover {background-color: rgba(255,255,255,0.3) !important;}}
.toolbar-icon-bg.open > a {background: none !important; &:hover {background: none !important;}}
.toolbar.pull-right li.toolbar-icon-bg > a {
	padding: 10px 5px;
	span.icon-bg {background-color: rgba(255,255,255,0.15); display: block; border-radius: @border-radius-base; padding: 5px 7px;}
}
.toolbar.pull-right li.toolbar-icon-bg:hover {

	span.icon-bg {background-color: rgba(255,255,255,0.3);}
}


// navbar-default toolbar-icon-bg
.navbar-default .toolbar-icon-bg.open > a span.icon-bg {background-color: rgba(0,0,0,0.1) !important; &:hover {background-color: rgba(0,0,0,0.1) !important;}}
.navbar-default .toolbar-icon-bg.open > a {background: none !important; &:hover {background: none !important;}}
.navbar-default .toolbar.pull-right li.toolbar-icon-bg > a {
	padding: 10px 5px;
	span.icon-bg {background-color: rgba(0,0,0,0.05); display: block; border-radius: @border-radius-base; padding: 5px 7px;}
}
.navbar-default .toolbar.pull-right li.toolbar-icon-bg:hover {

	span.icon-bg {background-color: rgba(0,0,0,0.1);}
}


a.username {
	padding: 13px 12px !important;
	font-size: @font-size-base !important;
	font-weight: 400;
	&:extend(.clearfix all);
	span {
		padding-right: 6px;
		font-size: @font-size-base;
		font-weight: 400;
	    //Visual Alignment
	    top:2px;
	    position: relative;
	    float: left;
	}
	img {
		height: 24px;
		width: 24px;
		float: right;
		border-radius: 50%;
	}
}

.dropdown-menu.userinfo {
	.username a {
		&:extend(.clearfix all);
		padding: 0;
		&:hover {text-decoration: none;background: none;}
	}
}

header.navbar {
	margin-bottom:0px; 
	border:none; 
	box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.08), 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
}

/* Nav and Sidebar Badges
// ------------------------*/

.nav .badge, .sidebar nav.widget-body>ul.acc-menu .badge {
	text-align: center;
	text-shadow: none !important;
}

.nav .badge {
	top: 6px;
	left: 21px;
	position: absolute;

	//background-color: @brand-danger;
}

.sidebar nav.widget-body>ul.acc-menu .badge {
	float: right;

	position: absolute;
  	//top: 14px;
  	right: 30px;
}

/* The toggle links for left/right sidebar */
.navbar .btn.pull-left {border-right: 1px solid #555; margin-right: 10px;}
.navbar .btn.pull-right {border-left: 1px solid #555; margin-left: 10px}


/* Menu Triggers */
#leftmenu-trigger, #rightmenu-trigger, #navbar-links-toggle {
	height: 50px; width: 50px; cursor: pointer;padding:5px;
}
#leftmenu-trigger {
	background-position: 0 0; float: left;
	&:hover {background-position: 0 50px;}
}
#rightmenu-trigger {
	background-position: 49px 0; float: right;
	&:hover {background-position: 49px 50px;}
}

#navbar-links-toggle {
	background-position: 0 0;
	&:hover {background-position: 0px 50px; background-color: transparent;}
}

// Alignments of dropdowns in small 
// screen manually so it centers
//-------------------------------
@media (max-width: @screen-xs-max) {
	
	.dropdown-menu.messages {
		margin-right: -35px;
		&:before, &:after {margin-right: 35px;}
	}
	.dropdown-menu.notifications {
		margin-right: -125px;
		&:before, &:after {margin-right: 125px;}
	}

	.dropdown-menu.taskprogresses {
		margin-right: -85px;
		&:before, &:after {margin-right: 85px;}
	}

	.dropdown-menu.search {
		margin-right: -83px;
		&:before, &:after {margin-right: 83px;}
	}

	// Menu Horizontal
	nav.navbar .open .dropdown-menu {
		position: relative;
		float: none;
		background: none;
		.box-shadow(~"none");
		border: 0;
	}
	nav.navbar .navbar-header {width: auto;}

	#topnav.navbar-default .yamm .dropdown-menu {
		border: 0;
	}
}

nav.navbar {margin-bottom: 0;}




//Keep topnav toolbar from collapsing even in small screens

header.navbar .navbar-nav .open .dropdown-menu {
	position: absolute;

}




// Flags

.language-switcher {
	.dropdown-menu {
		border-radius: @border-radius-base !important;
		background: #fff !important;
		color: @gray !important;
		padding: 0; 
		z-index: 1300;
		border: 1px solid @border-darker !important;
		box-shadow: 0 3px 1px rgba(0, 0, 0, 0.1) !important;
		ul {
			padding: 0px 0;
			.list-unstyled();
			li {
				border-bottom: 1px solid @border-lighter;
				&:last-child {border-bottom: none;}
				a {
					font-size: @font-size-small;
					color: @gray-dark !important;
					display: block;
					padding: 8px 15px;
					text-decoration: none;
					img {margin-right: 8px; margin-top: -3px;}
					&:hover {
						background: rgba(0, 0, 0, 0.03) !important; 
						color: @gray-darker !important;}
				}
			}
		}
	}
	img {width: 18px; margin-top: -4px;}
}



// Headerbar
#headerbar {
	top: -1000px;
	left: 0;
	position: fixed;
	padding: 20px 10px 0;
	z-index: 9999;
	width: 100%;
	background-color: #000;
	background-color: rgba(0, 0, 0, 0.86);
	-webkit-transition: top .25s ease;
	-o-transition: top .25s ease;
	transition: top .25s ease;
}