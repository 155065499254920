//Bootstrap 3 responsive columns of same height
//http://www.minimit.com/articles/solutions-tutorials/bootstrap-3-responsive-columns-of-same-height


.container-xs-height, .container-sm-height, .container-md-height, .container-lg-height,
.row-xs-height, .row-sm-height, .row-md-height, .row-lg-height {
	width: 100%;
}

/* columns of same height styles */
.container-xs-height {
	display:table;
	padding-left:0px;
	padding-right:0px;
}
.row-xs-height {
	display:table-row;
}
.col-xs-height {
	display:table-cell;
	float:none;
}
@media (min-width: @screen-sm) {
	.container-sm-height {
		display:table;
		padding-left:0px;
		padding-right:0px;
	}
	.row-sm-height {
		display:table-row;
	}
	.col-sm-height {
		display:table-cell;
		float:none;
	}
}
@media (min-width: @screen-md) {
	.container-md-height {
		display:table;
		padding-left:0px;
		padding-right:0px;
	}
	.row-md-height {
		display:table-row;
	}
	.col-md-height {
		display:table-cell;
		float:none;
	}
}
@media (min-width: @screen-lg) {
	.container-lg-height {
		display:table;
		padding-left:0px;
		padding-right:0px;
	}
	.row-lg-height {
		display:table-row;
	}
	.col-lg-height {
		display:table-cell;
		float:none;
	}
}


/* vertical alignment styles */
.col-top {
	vertical-align:top;
}
.col-middle {
	vertical-align:middle;
}
.col-bottom {
	vertical-align:bottom;
}