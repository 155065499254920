/*!
 * Yamm!3
 * Yet another megamenu for Bootstrap 3
 * 
 * http://geedmo.github.com/yamm3
 */

//-----------------------------
//  Yamm Styles
//-----------------------------

.yamm {

  // reset positions 
  .nav, .collapse, .dropup, .dropdown {
  	position: static;
  }  

  // propagate menu position under container for fw navbars
  .container {
  	position: relative;
  }

  // by default aligns menu to left
  .dropdown-menu {
  	left: auto;
  }
  // ensure a good position
  .nav.navbar-right .dropdown-menu {
  	left: auto;
  	right: 0;
  }

  // Content with padding
  .yamm-content {
  	padding: 10px 0px;
  }

  // Fullwidth menu
  .dropdown.yamm-fw .dropdown-menu {
  	left: 0; right: 0;
  }


  // Avalon
  .dropdown-menu {
  	border: none;
  	border-top-left-radius: 0px !important;
  	border-top-right-radius: 0px !important;
  	border-bottom-left-radius: @border-radius-base;
  	border-bottom-right-radius: @border-radius-base;
  }

}


// Custom classes

.yamm {

	.yamm-content {
		// padding: 0 20px;
		.yamm-col {
  			padding: 0 20px;
  		}
		h2, h3, h4 {
			//margin: 0 0 5px 0;
			padding: 0;
			&.yamm-category {
				letter-spacing: 0.1em;
				font-weight: 400;
				font-size: @font-size-base; 
				text-transform: uppercase; 
				color: @gray;
			}
		}

		ul {
			li {
				color: @navbar-inverse-color;
				margin: 0 -20px;
				i {margin-right: 6px;}
				a {
					color: @navbar-inverse-link-color;
					display: block;
					padding: 5px 20px !important;
				}
				&:hover {
					background-color: @navbar-inverse-link-hover-bg;
					a {
						color: #fff;
						text-decoration: none;
					}
				}

				p {
					margin: 0;
				}
			}
		}
	}
}