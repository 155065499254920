/*
 * Colorpicker for Bootstrap
 *
 * Copyright 2012 Stefan Petre
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 */

@colorPickerSaturationImage: '../plugins/form-colorpicker/img/bootstrap-colorpicker/saturation.png';
@colorPickerHueImage:        '../plugins/form-colorpicker/img/bootstrap-colorpicker/hue.png';
@colorPickerAlphaImage:      '../plugins/form-colorpicker/img/bootstrap-colorpicker/alpha.png';

.colorpicker-saturation {
	width: 100px;
	height: 100px;
	background-image: url('@{colorPickerSaturationImage}');
	cursor: crosshair;
	float: left;
	i {
		display: block;
		height: 5px;
		width: 5px;
		border: 1px solid #000;
		// border-radius: 5px;
		position: absolute;
		top: 0;
		left: 0;
		margin: -4px 0 0 -4px;
		b{
			display: block;
			height: 5px;
			width: 5px;
			border: 1px solid #fff;
			// border-radius: 5px;
		}
	}
}
.colorpicker-hue,
.colorpicker-alpha {
	width: 15px;
	height: 100px;
	float: left;
	cursor: row-resize;
	margin-left: 4px;
	margin-bottom: 4px;
	i {
		display: block;
		height: 1px;
		background: #000;
		border-top: 1px solid #fff;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		margin-top: -1px;
	}
}
.colorpicker-hue {
	background-image: url('@{colorPickerHueImage}');
}
.colorpicker-alpha {
	background-image: url('@{colorPickerAlphaImage}');
	display:none;
}
.colorpicker {
	.clearfix();
	top: 0;
	left: 0;
	padding: 4px;
	min-width: 120px;
	max-width: 150px;
	margin-top: 1px;
	z-index: 9999 !important;
	// border-radius: 4px;
	&:before {
		content: '';
		display: inline-block;
		border-left:   7px solid transparent;
		border-right:  7px solid transparent;
		border-bottom: 7px solid #ccc;
		border-bottom-color: rgba(0,0,0,.2);
		position: absolute;
		top: -7px;
		left: 6px;
	}
	&:after {
		content: '';
		display: inline-block;
		border-left:   6px solid transparent;
		border-right:  6px solid transparent;
		border-bottom: 6px solid #fff;
		position: absolute;
		top: -6px;
		left: 7px;
	}
	div {
		position: relative;
	}
	&.alpha {
		min-width: 140px;
		.colorpicker-alpha {
			display: block;
		}
	}
}
.colorpicker-color {
	height: 10px;
	margin-top: 5px;
	clear: both;
	background-image: url('@{colorPickerAlphaImage}');
	background-position: 0 100%;
	div {
		height: 10px;
	}
}
.input-group {
	&.color {
		.input-group-addon i {
			display: block;
			cursor: pointer;
			width: 16px;
			height: 16px;
		}
	}
}