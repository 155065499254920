//dropdowns.less

.caret {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  display: inline;
  margin-left: 4px;
  border:0;

  vertical-align: initial;  
  line-height: 1;
	&:before {
    	content: "\f107"; 
    	font-family: FontAwesome;
    	font-style: normal;
    	font-weight: normal;
    	text-decoration: inherit;
	}
}

.dropup .caret {
	border: 0;
	&:before {
    	content: "\f106"; 
    }
}

.yamm .dropdown-menu {
	right: auto;
}

.dropdown-menu {
	left: auto; right: 0;
	margin-top: 2px;
	border: 1px solid @border-darker;
	box-shadow: 0 3px 1px rgba(0, 0, 0, 0.1);
	background-color: @dropdown-fallback-bg;
	background-color: @dropdown-bg;
	padding: 8px 0;
	&.pull-left {
		left: 0; right: auto;
	}
	>li>a {
		padding: 5px 15px;
	}
	&.arrow {
		&:before, &:after {
			position: absolute; 
			top: -10px; 
			right: 10px;
			display: inline-block; 
			content: '';
			border-right: 10px solid transparent; 
			border-left: 10px solid transparent; 
		}
		&:before {
			border-bottom: 10px solid #4e4e4e; 
		}
		&:after {
			border-bottom: 10px solid #fff;
		}
	}
	.divider {
		margin: 8px 0;
	}
}

//wells.less

.well {
	padding: 20px;
	border: none;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    &.well-transparent  {
		background: none;
	}
	&.well-code {
		font-size: @font-size-small;
		font-family: @font-family-monospace;
	}
}

//list-group.less

.list-group { //tabdrop fix
	> .tabdrop + .list-group-item {
		.border-top-radius(@list-group-border-radius);
	}
}